import { Route, Routes } from 'react-router';
import './App.css';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Crypto from './Components/Crypto';
import Romance from './Components/Romance';
import SocialMedia from "./Components/SocialMedia";
import Blackmail from "./Components/Blackmail"
import PhoneUnlock from './Components/PhoneUnlock';
import AccountUnlock from "./Components/AccountUnlock";
import About from './Components/About';
import Contact from "./Components/Contact";

function App() {
  return (
   <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/navbar' element={<Navbar/>}/>
      <Route path='/Crypto' element={<Crypto/>}/>
      <Route path='/romance' element={<Romance/>}/>
      <Route path='/socialmedia' element={<SocialMedia/>}/>
      <Route path='/blackmail' element={<Blackmail/>}/>
      <Route path='/PhoneUnlock' element={<PhoneUnlock/>}/>
      <Route path='/AccountUnlock' element={<AccountUnlock/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
   </Routes>
  );
}

export default App;
