import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { useRef, useEffect } from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import Reviews from './hooks/Reviews';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circle from "./hooks/Circle";
import Circlex from "./hooks/Circlex";
import Circlexx from "./hooks/Circlexx";
import { Link } from "react-router-dom";
import Footer from "./hooks/Footer";
import Tooltip from '@mui/material/Tooltip';
import Progress from "./hooks/Progress";

const Home = () => {
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };
    const elementRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            elementRef.current.classList.add('shake-5s');
          } else {
            elementRef.current.classList.remove('shake-5s');
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    observer.observe(elementRef.current);

    // Cleanup the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }
  
  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };
  
  const fabWhiteStyle = {
      color: 'common.white',
      bgcolor: 'primary',
      '&:hover': {
        color: '#29d090', // Change the color on hover
        bgcolor: '#007FFF',
      },
    };
  
 
    const [value, setValue] = React.useState(0);
    const isMobile = useMediaQuery('(max-width: 600px)');
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const transitionDuration = 500;
  
    const fabs = [
      {
        color: 'primary',
        sx: fabStyle,
        icon: <StarIcon />, // Icon for Excellence
        label: 'Excellence',
      },
      {
        color: 'primary',
        sx: fabStyle,
        icon: <SecurityIcon />, // Icon for Integrity
        label: 'Integrity',
      },
      {
        color: 'primary',
        sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
        icon: <GroupIcon />, // Icon for Teamwork
        label: 'Teamwork',
      },
    ];
  return (
   <>
   <Progress/>
        <div className="box1 overflow-hidden">
            <section class="herox">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="logox.png" alt="" className="w-[10%]"/> */}
                        </div>
                </motion.div>
                 <div className='relative'>
                    <Navbar/>
                 </div>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>Your Reliable Asset Recovery Partner!</p>
                                </motion.div>

                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Expert Team & Cutting-Edge<br/>Technology for Swift Retrieval</p>
                                </motion.div>

                                <motion.div className='pt-2'
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>
                                    Specializing in expert asset recovery services for both individuals and businesses, 
                                    we assure you of swift and seamless asset retrieval.
                                    </p>
                                </motion.div>

                                <div className='flex gap-2'>
                                    <div className='bg-[reen] h-fit flex gap-1 mr-0 mt-5'>
                                        <a href="/contact"
                                            
                                        >
                                            <button class="button-57" ><span class="text">Report Scam<i class="ri-arrow-right-line"></i></span><span>Click!!</span></button>
                                        </a>
                                    </div>
                                    <div className='bg-[reen] h-fit flex gap-1 mr-1 mt-5'>
                                        <a href="https://showmechanism.com/signup"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                            <button class="button-58" ><span class="text">Sign-up Now<i class="ri-arrow-right-line"></i></span><span>Click!!</span></button>
                                        </a>
                                    </div>
                                
                                </div>
                                <div className="w-full md:w-1/3">
         
          <div className="flex space-x-4 pt-5">
          <a
            href="https://www.instagram.com/showmechanism?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            className="text-white hover:text-gray-500"
            target="_blank"
            rel="noopener noreferrer"
            >
               <i className="ri-instagram-line text-[1.3rem]"></i>
          </a>


            <a href="mailto:showmechanism@gmail.com" className="text-white hover:text-gray-500"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="ri-mail-open-line text-[1.3rem]"></i>
            </a>

            <a href="https://t.me/Showmechanism" className="text-white hover:text-gray-500"
                target="_blank"
                rel="noopener noreferrer"
            >
            <i class="ri-telegram-fill text-[1.3rem]"></i>
            </a>
            <a href="https://www.tiktok.com/@show_mechanism?is_from_webapp=1&sender_device=pc" className='hover:text-gray-500'
                target="_blank"
                rel="noopener noreferrer"
            >
              <i class="ri-tiktok-line text-[1.3rem]"></i>
            </a>

            <a href="https://wa.me/+14062896580" className='hover:text-gray-500'
                target="_blank"
                rel="noopener noreferrer"
            >
              <i class="ri-whatsapp-line text-[1.3rem]"></i>
            </a>
          </div>
        </div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <div className='bg-[#0f1419] h-fit'>
                <div className='flex justify-center items-center pt-10'>
                    <p className='font-bold text-white shake-5s'>Policy’s</p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Top Policy</p>
                </div>



            <div className='mx-auto md:max-w-7xl overflow-hidden'>
                <div className='grid mt-10 xl:mt-0 ml-1 mr-1 grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="return-of-investment.png" alt="" className='w-[40px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Refund Policy</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            With a wealth of expertise at our disposal, we maintain a high success rate, minimizing refund requests. If you're unsatisfied, rest assured that we offer a swift and hassle-free refund process.
                            </p>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="privacy.png" alt="" className='w-[40px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Privacy Policy</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Detailing our commitment to safeguarding clients' personal and financial information, 
                            our policy adheres to all pertinent data protection regulations. Rest assured, your sensitive data is secure with us.
                            </p>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.7, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="accuracy.png" alt="" className='w-[40px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Accuracy</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Discover the excellence of our versatile and dynamic team, proficient in various fields. Witness our seamless collaboration in achieving impeccable results, leaving no room for error.
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>


            <section class="numbers mt-[5%]">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.2, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="bitcoin.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            $<AnimatedNumbers value={37}/>m+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Money Restored
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.4, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="value.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={11460}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Satisfied Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.6, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="trust.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={10930}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Trusted 
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.7, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="encrypted.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={7940}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Protected Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                  </div>
               </div>
            </div>
            </section>




                <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'>Services <i class="ri-sparkling-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Key Services </p>
                </div>




            <div className='mx-auto md:max-w-7xl overflow-hidden'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="increase.png" alt="" className='w-[50px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Crypto Currency Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                                Have you ever felt the frustration of lost funds or cryptocurrency slipping through your grasp? Worry not, for we're here to guide you through the intricate path of their recovery. Rest assured, with our expertise, you can regain access to your locked-out wallet. Your journey is made smoother with the adept assistance, led by Money Revoke Experts and their dedicated team of professionals.
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Crypto Currency Recovery" arrow>
                          <a href="/crypto">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="hearts.png" alt="" className='w-[50px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Romance Scam Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Assisting Those Affected by Romance Scams, we're committed to helping you overcome the distress caused by these deceitful actions. With our focused experts, we're determined to rebuild your confidence and guide you through the recovery of your lost assets. Trust us to lead you towards recovery with unwavering commitment. 
                            </p>
                        </div>

                       <div className='mt-10'> 
                        <Tooltip title="Romance Scam Recovery" arrow>
                          <a href="/romance">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                       </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="instagram.png" alt="" className='w-[50px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Social Media Hack & Recovery</p>
                        </div>

                        <div className='pt-2'>
                            <p className='text-white'>
                            Empowering Your Digital Connections: Social accounts act as crucial links to numerous achievements and cherished connections. Recognizing their importance, we stand unwavering in our dedication to surpass expectations, leaving no stone unturned in ensuring your swift account recovery. Trust in our unwavering expertise and commitment to promptly reinstate your access
                            </p>
                        </div>

                        <div className='mt-0'> 
                         <Tooltip title="Social Media Hack & Recovery" arrow>
                          <a href="/socialmedia">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                       </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="blackmail.png" alt="" className='w-[50px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Help from Blackmail & Extortion</p>
                        </div>

                        <div className='pt-2'>
                            <p className='text-white'>
                            Empowering and Advocating for Victims of Online Blackmail, Sextortion, and Threats. Our dedicated mission is to provide unwavering support and compassionate assistance to individuals who have unfortunately encountered these distressing experiences. We steadfastly stand by their side, offering expert guidance to those who have fallen prey to online threats and malicious intent.
                            </p>
                        </div>

                        <div className='mt-0'> 
                         <Tooltip title="Help from Blackmail & Extortion" arrow>
                          <a href="/blackmail">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                       </div>
         
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="device.png" alt="" className='w-[50px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Phone & Device Hack/Unlock</p>
                        </div>

                        <div className='pt-2'>
                            <p className='text-white'>
                           Showmechanism, Comprising a team of esteemed specialists in phone unlocking, rest assured, no detail escapes our scrutiny. Our meticulous expertise and unwavering commitment guarantee thoroughness in each phase, delivering definitive solutions tailored to your requirements. Place your trust in us for an unparalleled, hassle-free experience.
                            </p>
                        </div>

                        <div className='mt-0'> 
                            <Tooltip title="Phone & Device Hack/Unlock" arrow>
                          <a href="/phoneunlock">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                       </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.7, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="3d-unlocked.png" alt="" className='w-[50px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Account Unlock & Recovery</p>
                        </div>

                        <div className='pt-2'>
                            <p className='text-white'>
                            Unlock the potential to regain access to your locked accounts with our tailored tools. Experience the convenience of our solutions designed to overcome account lockouts effortlessly. At Showmechanism, we specialize in swift account recovery, providing you with the means to regain control and peace of mind for your digital assets.
                            </p>
                        </div>

                        <div className='mt-0'> 
                            <Tooltip title="Account Unlock & Recovery" arrow>
                          <a href="/accountunlock">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                       </div>
                    </motion.div>
                </div>
            </div>



                 <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> <i class="ri-double-quotes-l text-[#007FFF]"></i> Testimonies <i class="ri-double-quotes-r text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Top Reviews </p>
                </div>


        
            <div className='mx-auto md:max-w-7xl overflow-hidden'>
                <Reviews/>   
            </div>


                <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> Core Values <i class="ri-medal-2-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Why Choose Us</p>
                </div>

                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-gree-600 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://www.dnv.com/Images/Synergi-Life-Cyber-security_1000x500_tcm8-157741.jpg" alt="" />
                            </div>
                        </div>
                        <div className='bg-green600 h-fit'>
                        <div className='bg-[reen] h-fit'>
                            <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
                                <CssBaseline />
                                    <Box
                                        sx={{
                                            bgcolor: '#181a29', // Change the background color here
                                            width: '100%',
                                            position: 'relative',
                                            minHeight: 200,
                                            marginLeft: 0,
                                             marginRight: 0,
                                        }}
                                    >
                                    <AppBar position="static" sx={{ backgroundColor: "#111639" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="#007FFF" // Change the indicator color here
                                            textColor="#29d090"
                                            variant="fullWidth"
                                            aria-label="action tabs example"
                                            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#0097D7" } }}
                                        >
                                            <Tab label="Excellence" {...a11yProps(0)} />
                                            <Tab label="Integrity" {...a11yProps(1)} />
                                            <Tab label="Teamwork" {...a11yProps(2)} />
                                      </Tabs>
                                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Continuous Improvement</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                             Through continuous refinement, we work diligently and tirelessly to surpass your expectations. Our unwavering commitment ensures that we won't rest until we've achieved excellence and completed the job to your utmost satisfaction.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Reliable Integrity</p>
                        </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                            Unwavering Ethical Commitment: We uphold the highest standards of trustworthiness and ethical conduct. Our reliable integrity is the cornerstone of our service, ensuring your complete confidence in our work. We are dedicated to maintaining your trust, always, because your peace of mind matters.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div>
                            <p className='font-bold text-[#C0C2CD]'>Resilient Teamwork</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                            Collaborative Determination: Our resilient teamwork thrives on collaboration and determination. We unite as a dedicated team, facing challenges head-on and delivering results together. With unwavering determination, we work seamlessly to achieve your goals, every step of the way.
                        </div>
                    </TabPanel>
                        {fabs.map((fab, index) => (
                            <Zoom
                                key={fab.color}
                                in={value === index}
                                timeout={transitionDuration}
                                style={{
                                transitionDelay: `${value === index ? transitionDuration : 0}ms`,
                            }}
                                unmountOnExit
                            >
                                <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
                                {fab.icon}
                                </Fab>
                            </Zoom>
                            ))}
                    </Box>
                </Container>
                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Success</p>
                                </div>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>
        </div>

                    <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>

            <Footer/>
    </div>
   </>
  )
}

export default Home