import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import StarIcon from '@mui/icons-material/Star';


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  
    const customColorStyle = {
      color: 'yellow',
    };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/736x/4e/c4/3d/4ec43d79f28a1d00aff692229aeeecf0.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Magnolia Hawthorne</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I never thought I'd find myself in such a horrifying situation. Someone had obtained my private photos and started 
                    blackmailing me for over $70,000. I felt helpless and violated. Thankfully, a friend recommended Showmechanism, 
                    and they immediately went to work. Not only did they recover the money taken from me, but they also helped trace the 
                    blackmailer, bringing them to justice. I can't express how grateful I am for their expertise and support.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Louisiana • USA •  4 weeks ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       

        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://media.istockphoto.com/id/1079932454/photo/selfie-in-bedroom.jpg?s=612x612&w=0&k=20&c=Sqj21EF0ppcBVzkUJ47DAgbp8buiedjCfW3OQCeOXfU=' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Jethro Beauregard</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I received terrifying threats from an anonymous person who claimed to have my address. They demanded $47,000, 
                    threatening to harm my family if I didn't comply. I was living in constant fear until I contacted Showmechanism
                    Backs. They not only eliminated the online threat but also assisted in tracking down the perpetrator and ensuring 
                    justice was served. Showmechanism truly saved my life and my family's.
                    </p>


                    <div className='pl-2 pb-3 pt-[5%]'>
                        <p className='text-[#b0b0b0] text-center '> Mississippi • USA • 5 days Ago</p>
                    </div>
                </div>

                </div>   
        </div>
        </div>
        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/736x/f4/da/ec/f4daeccf7a4fef4600d13d11ee15b98d.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Jessy Hartel</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    As a public figure, my reputation is everything. When compromising information was used against me for extortion, I 
                    was devastated. Showmechanismprovided unwavering support and expertise. They not only prevented the release of 
                    sensitive information but also tracked down the extortionist, ensuring they faced the consequences of their actions. 
                    Thanks to Showmechanism, my reputation remains intact.
                    </p>


                    <div className='pl-2 pb-3 pt-10'>
                        <p className='text-[#b0b0b0] text-center'> Scotland • United Kingdom • 2 weeks ago</p>
                    </div>
                </div>

                </div>   
        </div>
        </div>


        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/originals/7c/ff/e6/7cffe6d529f700815690165f4e264d1f.png' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Elodie Dupont</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I became a victim of relentless cyberbullying and blackmail that took a toll on my mental health. Showmechanism stepped in to help. They not only put an end to the bullying but also identified the culprit. With their 
                    support, I regained control of my life and felt empowered to stand up against online harassment. Showmechanism
                    Backs is a beacon of hope for those facing similar situations.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Quebec • Canada • 12 days Ago</p>
                    </div>
                </div>

                </div>   
        </div>
        </div>
        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://1.bp.blogspot.com/-v0RkfzSyp00/YQPrkDSMcJI/AAAAAAAALf0/ZQQUSUFC_Vgcx7gSaV3d_TNKhraSM-_GwCLcBGAsYHQ/s752/attractive-men-fit-body-arm-tattoo-selfie.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Liam Callaghan</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    My family's safety was at risk as an online blackmailer threatened to harm them if I didn't pay a 
                    hefty sum. It felt like a nightmare. Fortunately, I reached out to Showmechanism, who not only 
                    eliminated the threat but also cooperated with law enforcement to ensure the blackmailer faced justice.
                     Thanks to their expertise and dedication, my family is safe, and justice prevailed.
                    </p>


                    <div className='pl-2 pb-3 pt-5'>
                        <p className='text-[#b0b0b0] text-center'> Ontario • Canada • 1 month Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p className='text-white'>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;