import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import StarIcon from '@mui/icons-material/Star';


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  
    const customColorStyle = {
      color: 'yellow',
    };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper  rounded-md'>
           <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/1200x/55/29/03/55290328c23fccf2257ab0844c6f71bf.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Maximilian Beauregard</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I had a terrible experience with a fake recovery firm that promised to restore my stolen Bitcoin but ended up taking more money from me. Thankfully, I crossed paths with Showmechanism, and they came to my rescue. They not only restored my stolen Bitcoin but also ensured I got every penny back. I owe them a lot for their honesty and expertise.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Alamba • USA • 1 week Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       

        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://preview.redd.it/started-cleaning-my-room-but-ended-up-taking-selfies-v0-ejz69dvz18nb1.jpg?width=640&crop=smart&auto=webp&s=bde9b4e664f038c04072e1e054e39a07af308b55' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Seraphina Thistlewood</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I mistakenly sent $60,000 worth of Bitcoin to the wrong wallet, and I thought I had lost it forever. A friend recommended Showmechanism, and they worked their magic. With ease, they restored my money. These experts are the best in the business, and I'm immensely grateful.
                    </p>


                    <div className='pl-2 pb-3 pt-[13%]'>
                        <p className='text-[#b0b0b0] text-center '> Oakvillie • Canada • 5 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/564x/e1/3a/4b/e13a4b08619959fdf9dc732df6fae3d6.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Percival Ravenscroft</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I had been investing with a fake broker site for months, only to discover it was a scam that had swindled me out of $100,000. In my despair, I contacted Showmechanism, and they didn't waste a moment. They swiftly restored my money, sparing me any further agony. Their efficiency is unmatched.
                    </p>


                    <div className='pl-2 pb-3 pt-8'>
                        <p className='text-[#b0b0b0] text-center'> Oregon • USA • 2 weeks ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>


        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/1200x/99/7e/fd/997efd72a41863bc06386d87bd408ca5.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Thaddeus Wainwright</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    After falling victim to a Bitcoin scam and losing $150,000, I was left devastated and in disbelief. It seemed like all hope was lost until I saw their recommendations online and decided to contact Showmechanism. Their team of professionals worked tirelessly to recover my lost funds. I can now say that I've been given a second chance at financial security, thanks to them.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Massachusetts • USA • 6 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCuDyRTQxCc5gtQOJCOWq1NHSUCNrZKhYgQ9GT9Yxv4ihhd7BFhbAnSWjCIrdOZe4CdL0&usqp=CAU' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Calista Nightshade</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    Being a victim of a cryptocurrency scam was a nightmare. I thought I'd never see my hard-earned Bitcoin again. However, Showmechanism proved me wrong. They not only restored my Bitcoin but also provided incredible support throughout the process. I'm forever grateful for their dedication and expertise.
                    </p>


                    <div className='pl-2 pb-3 pt-9'>
                        <p className='text-[#b0b0b0] text-center'> New Castle • United Kingdom • 1 day Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p className='text-white'>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;