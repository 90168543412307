import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import StarIcon from '@mui/icons-material/Star';


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  
    const customColorStyle = {
      color: 'yellow',
    };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/736x/be/7c/99/be7c99d475d6ba3b98adb07588ac0994.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Evelyn Turner</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-2 text-center pb-0 text-[#e3e3e5]'>
                    My Instagram account got hacked and disabled, causing me a lot of distress. Showmechanism came to my rescue, 
                    swiftly recovering my account and providing valuable security guidance. Their quick response and expertise made all 
                    the difference. I'm incredibly grateful for their exceptional service. If you have any more requests or need further adjustments, feel free to ask.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Atlanta • USA •  6 Hours Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       

        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/originals/21/ca/e6/21cae6fe76ada71cb539dd8675ee7674.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Claire Snow</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    My Snapchat account had been disabled for months, and I was desperately trying to recover it as it held countless precious memories. That's when I discovered Showmechanism. They not only effortlessly restored my account but also helped me enhance its security. I'm incredibly grateful to have found this remarkable team.
                    </p>


                    <div className='pl-2 pb-3 pt-[12%]'>
                        <p className='text-[#b0b0b0] text-center '> Memphis • USA • 3 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://we-ha.com/wp-content/uploads/2020/02/Labbe-for-WWIN.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Zephyra Hawthorne.</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-2 text-center pb-0 text-[#e3e3e5]'>
                    For months, my husband had been deceiving me, diverting money to his secret girlfriend. My suspicions grew, but I 
                    needed concrete evidence. That's when a friend recommended Showmechanism. They Helped Hacked into hes Account 
                     and now I 
                    possess undeniable proof of his infidelity and financial misconduct. Thanks to Showmechanism, he's repaying 
                    all the money he owed me.
                    </p>


                    <div className='pl-2 pb-3 pt-4'>
                        <p className='text-[#b0b0b0] text-center'> Orlando • USA • 2 Days ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>


        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://media.istockphoto.com/id/1213122500/photo/image-of-young-handsome-hindu-man-in-early-20s-taking-selfie-with-phone-camera-holding-mobile.jpg?s=612x612&w=0&k=20&c=lU02dqId7GzmBSFjes9ZksBlaB5orrxsS8Arjg5PCg8=' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Aarav Sharma</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    My Facebook account fell victim to hacking, leaving an unknown individual misusing it, soliciting money from my friends. Desperate for a solution, I reached out to Showmechanism. Their team swiftly restored my account and fortified it with added protection. Now, no cyber threat can come close to it.
                    </p>


                    <div className='pl-2 pb-3 pt-[15%]'>
                        <p className='text-[#b0b0b0] text-center'> Jaipur • India • 5 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRibRCeOMEOBm8PAJdBlcKH7lg6NUfiwQRASA&usqp=CAU' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]"> Owen Bennett</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    My Instagram account  of 789k Followers was disabled for months, depriving me of countless cherished memories. Despite my efforts, I couldn't recover it. Then, I discovered Showmechanism, and they effortlessly restored my account. Not only that, but they also bolstered my account's security. I'm grateful to have found this remarkable team.
                    </p>


                    <div className='pl-2 pb-3 pt-7'>
                        <p className='text-[#b0b0b0] text-center'> British Columbia • Canada • 9 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p className='text-white'>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;