import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import StarIcon from '@mui/icons-material/Star';


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  
    const customColorStyle = {
      color: 'yellow',
    };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://www.informationng.com/wp-content/uploads/2017/11/toni-kelly-new-zealand-1148841.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Iris Rosso</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    For years, I've been dating a man named Jonah Tennison on Facebook. He promised to spend the rest of his life with me, and I loved him deeply. Out of love, I lent him $50,000. However, he suddenly blocked me and disappeared. It was devastating. Thankfully, I heard about Showmechanism. Their team, with their speedy and efficient service, restored my money. I owe them a tremendous debt of gratitude.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Georgia • USA •  3 weeks ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       

        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://res.cloudinary.com/graham-media-group/image/upload/f_auto/q_auto/c_scale,w_400/v1/production/public/PN6FYUGPCBEC7PKZH57W7RDDCE.jpg?_a=AJFJtWIA' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Kaelen Finn</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I met a girl on Tinder who promised to have a great time with me. She seemed genuine, but it turned out she scammed me and took about $20,000. She never showed up. Desperate for help, I stumbled upon recommendations for Showmechanism. This amazing team of experts restored my money, and I am 100% grateful to them.
                    </p>


                    <div className='pl-2 pb-3 pt-[12%]'>
                        <p className='text-[#b0b0b0] text-center '> Toronto • Canada • 8 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/736x/ba/de/98/bade9840e5a72c3049d223efb97d94d8.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Isabella Wright</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I thought I had found true love online until my so-called lover asked me to invest in a shady business. Trustingly, I invested $76,000, only to be scammed and left behind. Fortunately, this incredible team at Showmechanism came to my rescue. They restored my money without wasting any time. 
                    </p>


                    <div className='pl-2 pb-3 pt-10'>
                        <p className='text-[#b0b0b0] text-center'> QueensLand • Australia • 1 week ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>


        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://media-cdn.tripadvisor.com/media/photo-p/0e/6a/92/a4/a-selfie-in-room-worth.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Isla Montgomery</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I was deeply in love with someone I met online, and we planned to meet in person. To make our dream come true, I sent her $45,000 to cover her travel expenses. However, she vanished into thin air, and my heart and money were broken. In my despair, I reached out to Showmechanism, hoping for a miracle. They worked tirelessly, and with their expertise, they recovered my funds. I can't express how thankful I am.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Baltimore • USA • 2 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
        <div className='slide-wrapper '>
        <div className="bg-[#111639] h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://preview.redd.it/i-am-laying-in-bed-in-a-hotel-room-v0-a6vbyaikwqua1.jpg?width=640&crop=smart&auto=webp&s=156b436a951edbe06a4691da34cc5e9c86e27d1a' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Alex Bellamy</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I met the love of my life on a dating app, and we connected instantly. However, she convinced me to invest $30,000 in a supposed 'business opportunity.' It turned out to be a romance scam, and my trust was shattered. Fortunately, I discovered Showmechanism, and they helped me reclaim my money and my belief in true love. Their dedication and support meant the world to me. - Testified by Ethan Mitchell, Toronto, Canada, 2 months ago.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> British Columbia • Canada • 4 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p className='text-white'>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;